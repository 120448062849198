module.exports = {
  getSiteData
};

function getSiteData(gql, {endpoint, clientId, clientSecret, appName, siteId}) {
  return fetchAccessToken(endpoint, clientId, clientSecret).then((token) =>
    fetchSite(gql, token, endpoint, appName, siteId)
  );
}

function fetchAccessToken(endpoint, clientId, clientSecret) {
  return fetch(`${endpoint}/identity-server/connect/token`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: `grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}&scope=squidex-api`
  })
    .then((res) => res.json())
    .then((data) => {
      const {access_token} = data;
      if (typeof access_token !== 'string') {
        throw Error(`Access token cannot be fetched, ${JSON.stringify(data || '')}`);
      }
      return access_token;
    });
}

function fetchSite(gql, accessToken, endpoint, appName, siteId) {
  const methodName = buildFindMethodName('site');
  const query = `
  query {
  ${methodName}(id: "${siteId}") {
    data {
      name {
         iv
      }
      themes {
        iv {
          name
          file {
            id
          }
        }
      }
      pages {
        iv {
          page {
            data {
              value {
                iv
              }
            }
          }
        }
      }
      deps {
        iv {
          data {
            value {
              iv
            }
          }
        }
      }
    }
  }
  }`;
  return fetchContent(gql, endpoint, appName, accessToken, query).then((data) => {
    const siteData = data[methodName].data;
    return {
      name: siteData.name.iv,
      themes: siteData.themes.iv.map((item) => ({
        name: item.name,
        url: buildAssetUrl(endpoint, appName, item.file[0].id)
      })),
      widgetTree: siteData.pages.iv[0].page[0].data.value.iv,
      extraDependencies: siteData.deps?.iv?.[0]?.data?.value?.iv
    };
  });
}

function buildFindMethodName(contentName) {
  return `find${contentName.charAt(0).toUpperCase()}${contentName.slice(1)}Content`;
}

function buildAssetUrl(endpoint, appName, assetIdOrSlug) {
  return `${endpoint}/api/assets/${appName}/${assetIdOrSlug}`;
}

function fetchContent(gql, endpoint, appName, accessToken, query) {
  const graphQLClient = new gql(`${endpoint}/api/content/${appName}/graphql`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  return graphQLClient.request(query);
}
