import {registerApplication, start} from 'single-spa';

export function startApplication(_widgetTree, decorators = []) {
  start();
  _widgetTree.props.noTenant = Boolean(process.env.NO_TENANT)
  const rendererPromise = System.import('@netvision/lib-widget-renderer');
  return System.import('@netvision/lib-merge-widget-tree').then(({applyUserSetting}) => {
    applyUserSetting(_widgetTree).then((widgetTree) => {
      registerApplication(
        'renderer',
        () => rendererPromise,
        () => true,
        {
          widgetTree,
          decorators
        }
      );

      start();
    });
  });
}
