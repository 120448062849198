export function insertImportMap(importMapObject) {
  // insert a new Import Map
  const scriptEl = document.createElement('script');
  scriptEl.type = 'systemjs-importmap';
  scriptEl.textContent = JSON.stringify(importMapObject, null, 2);
  // if overrides are used, insert before overrides and mark overrides as not processed
  const overrides = document.getElementById('import-map-overrides');
  if (overrides) {
    overrides.sp = false;
    overrides.before(scriptEl);
  } else {
    document.head.appendChild(scriptEl);
  }
  // trigger import map re-evaluation
  System.prepareImport(true);
}
