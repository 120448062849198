import ResizeObserver from 'resize-observer-polyfill';

// Font node allows to efficiently keep track of rem to pixel ratio
export function addFontNode() {
  const fontNodeId = 'rem-gauge';
  const fontNode = document.createElement('div');
  fontNode.id = fontNodeId;
  fontNode.style.position = 'absolute';
  fontNode.style.opacity = '0';
  fontNode.style.pointerEvents = 'none';
  fontNode.style.fontSize = '1rem';
  fontNode.innerText = 'innerText';
  window.document.body.append(fontNode);
  const ro = new ResizeObserver(([entry]) => {
    entry.target.setAttribute('rem', rem().toString());
    entry.target.dispatchEvent(new CustomEvent('update'));
  });
  ro.observe(fontNode);

  function rem() {
    const pageFontSize = Number(
      getComputedStyle(document.documentElement).getPropertyValue('font-size').replace('px', '')
    );
    const baseFontSize = Number(getComputedStyle(document.documentElement).getPropertyValue('--bfs'));
    return pageFontSize / baseFontSize;
  }
}
