window.System.constructor.prototype.instantiate = new Proxy(window.System.constructor.prototype.instantiate, {
  apply(target, thisArg, argArray) {
    const url = argArray[0];
    // disable treating CSS as modules
    if (url.endsWith('.css')) {
      appendGlobalStyle(url);
      return Promise.resolve([
        [],
        function (_export) {
          return {
            execute: function () {
              _export('default', null);
            }
          };
        }
      ]);
      // fixes bug with .default import of some UMD modules
    } else {
      return Reflect.apply(target, thisArg, argArray).then((register) => {
        register[1] = new Proxy(register[1], {
          apply(target, thisArg, argArray) {
            const _export = argArray[0];
            _export('__esModule', true);
            return Reflect.apply(target, thisArg, argArray);
          }
        });
        return register;
      });
    }
  }
});

function appendGlobalStyle(href) {
  if (!document.head.querySelector(`link[href="${href}"]`)) {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = href;
    document.head.appendChild(link);
  }
}
