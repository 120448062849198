window.__NV_CONSTANTS__ = {
  get maxRecordDuration() {
    let num = Number(process.env.MAX_RECORD_DURATION);
    if (isNaN(num)) {
      return Infinity;
    } else {
      return num <= 0 ? Infinity : num;
    }
  }
};
