import './prepare';
import {GraphQLClient} from 'graphql-request';
import {startApplication} from './startApp';
import {getSiteData} from '../utils/getSiteDataFromSquidex';
import {addThemes} from './addThemes';
import {insertImportMap} from './insertImportMap';
import moduleFileName from '../utils/moduleFileName';
import "regenerator-runtime/runtime";

const {
  endpoint = process.env.API_ENDPOINT,
  appName = process.env.API_APP_NAME,
  clientId = process.env.API_CLIENT_ID,
  clientSecret = process.env.API_CLIENT_SECRET,
  siteId = process.env.API_SITE_ID
} = {};

getSiteData(GraphQLClient, {endpoint, clientId, clientSecret, appName, siteId}).then((data) => {
  if (data) {
    addThemes(data);
    if (typeof data.extraDependencies === 'object') {
      data.extraDependencies.forEach(({name, src, enabled}) => {
        if (enabled) {
          if (!src) {
            src = `${endpoint}/api/assets/${appName}/${moduleFileName(name)}.js`;
          }
          insertImportMap({
            imports: {
              [name]: src
            }
          });
        }
      });
    }
    startApplication(data.widgetTree, decorators);
  }
});

const visited = new Set();
const decorators = [
  {
    type: 'widget',
    decorate(widgetNode) {
      const {src} = widgetNode;
      if (!visited.has(src)) {
        visited.add(src);
        let moduleName;
        let path;
        if (src.startsWith('http')) {
          moduleName = src;
          path = src;
        } else {
          moduleName = src;
          path = `${endpoint}/api/assets/${appName}/${moduleFileName(moduleName)}.js`;
        }
        insertImportMap({
          imports: {
            [moduleName]: path
          }
        });
      }
    }
  }
];
