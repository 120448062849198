import {addFontNode} from './addFontNode';

const themeKey = 'netvision:theme';
const getSavedTheme = () => window.localStorage.getItem(themeKey);
const saveTheme = (value) => window.localStorage.setItem(themeKey, value.toString());

export function addThemes(siteData) {
  const themeEl = window.document.createElement('link');
  themeEl.id = 'theme';
  themeEl.rel = 'stylesheet';
  themeEl.type = 'text/css';
  document.head.appendChild(themeEl);

  const themes = siteData.themes;

  const emitUpdateThemeEvent = (value) => themeEl.dispatchEvent(new CustomEvent('update', {detail: value}));

  themeEl.setTheme = (newName) => {
    const theme = themes.find((t) => t.name === newName) || themes[0];
    if (typeof theme !== 'undefined') {
      themeEl.href = theme.url;
      themeEl.setAttribute('theme-name', theme.name);
      saveTheme(theme.name);
      emitUpdateThemeEvent(theme.name);
    }
  };

  window.addEventListener('storage', () => {
    themeEl.setTheme(getSavedTheme());
  });

  themeEl.setTheme(getSavedTheme());

  const browser = getBrowser();
  if (browser) {
    document.body.classList.add(browser);
  }

  addFontNode();
}

const getBrowser = () =>
  (function (agent) {
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge-legacy';
      case agent.indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!window.opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!window.chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return null;
    }
  })(window.navigator.userAgent.toLowerCase());
